import { render, staticRenderFns } from "./classListBj.vue?vue&type=template&id=ede00228&scoped=true"
import script from "./classListBj.vue?vue&type=script&lang=js"
export * from "./classListBj.vue?vue&type=script&lang=js"
import style0 from "./classListBj.vue?vue&type=style&index=0&id=ede00228&prod&lang=less"
import style1 from "./classListBj.vue?vue&type=style&index=1&id=ede00228&prod&lang=less&scoped=true"
import style2 from "./classListBj.vue?vue&type=style&index=2&id=ede00228&prod&lang=less&scope=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede00228",
  null
  
)

export default component.exports